<template id="">
  <div>
    <div class="admin">
      <div style="padding: 5em 50px 20px">
        <h3>案件詳細</h3>
        <hr />
      </div>
      <div
        class="uk-text-large"
        v-show="
          $store.state.common.sIsLoading || $store.state.common.sDataLoading
        "
      >
        Loading...
      </div>
      <div
        class="detailmenu"
        v-show="
          !$store.state.common.sIsLoading && !$store.state.common.sDataLoading
        "
      >
        <template v-if="detail != null && detail != undefined">
          <label v-if="sc_status == 5">
            csvアップロード
            <input type="file" style="display: none" @change="csv_to_db" />
          </label>
          <label v-if="sc_status == 2">
            宛名用エクセル出力
            <button style="display: none" @click="db_to_csv" />
          </label>
          <label v-if="sc_status == 6">
            入力用データ出力
            <button style="display: none" @click="get_input_data" />
          </label>
          <label v-if="sc_status == 6">
            分析結果アップロード
            <input
              type="file"
              name="file"
              @change="result_upload"
              accept="application/zip"
              style="display: none"
            />
          </label>
          <label v-if="sc_status >= 7 && sc_status != 9">
            高ストレス者一覧出力
            <button style="display: none" @click="get_high_stress_list()" />
          </label>
          <label v-if="sc_status == 8">
            報告書出力
            <button style="display: none" @click="output_report" />
          </label>
        </template>
        <table>
          <thead class="fixed01">
            <tr>
              <th>案件管理番号</th>
              <th>企業名</th>
              <th>ストレスチェック<br />依頼日時</th>
              <th>ストレスチェック<br />開始日</th>
              <th>ストレスチェック<br />終了日</th>
              <th>有償区分</th>
              <th>状態</th>
            </tr>
          </thead>
          <tbody class="fixed02">
            <template v-if="detail" v-cloak>
              <tr>
                <td>{{ detail.sc_id }}</td>
                <td>{{ detail.sc_com_name }}</td>
                <td>{{ detail.crt_date }}</td>
                <td>{{ detail.sc_answer_start_date }}</td>
                <td>{{ detail.sc_answer_end_date }}</td>
                <td>
                  <input
                    type="checkbox"
                    name=""
                    :checked="detail.sc_group_analysis"
                    onclick="return false;"
                  />
                </td>
                <td v-html="ret_status"></td>
              </tr>
            </template>
          </tbody>
        </table>
        <br />
        <div>
          <table>
            <thead class="fixed01">
              <tr>
                <th>従業員番号</th>
                <th>氏名</th>
                <th>所属部署</th>
                <th>集団分析グループ</th>
                <th>回答方法</th>
                <th>結果提示方法</th>
                <th>回答状況</th>
              </tr>
            </thead>
            <tbody class="fixed02">
              <template v-if="targets_list">
                <tr v-for="li in targets_list" :key="li.emp_code">
                  <td>{{ li.emp_code }}</td>
                  <td>{{ li.name }}</td>
                  <td>{{ li.wp_name }}</td>
                  <td>{{ li.analysis_group_code }}</td>
                  <td v-if="li.sc_conf_answer == 0">Web</td>
                  <td v-else>アンケート用紙</td>
                  <td v-if="li.sc_conf_notice == 0">Web</td>
                  <td v-else>紙</td>
                  <td v-if="li.answer_status == 0">未回答</td>
                  <td v-else-if="li.answer_status == 1">回答中</td>
                  <td v-else-if="li.answer_status == 2">回答済</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div>
          <label>
            従業員リスト取得
            <button style="display: none" @click="get_answer_status_list()" />
          </label>
        </div>
        <div v-if="sc_status < 8">
          <label>
            案件取り下げ
            <button style="display: none" @click="cancel_request" />
          </label>
        </div>
        <div class="">
          <label>
            戻る
            <button style="display: none" @click="$router.back()" />
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      sc_id: null,
      detail: null,
      targets_list: null,
      status: null,
      ank_num: 0,
      pdf_url: null,
      tmp: null,
      do_something: true,
      pdfs: [],
      lists: [],
      flg: true,
      pdf_folder_list: [],
      tmp1: null,
      blobs: null,
      change_flg: false,
      comment: "",
      pdf_status: null,
      multiple: null,
      pdf_name: null,
      p_flg: true,
      pdf_blobs: [],
      pdf_wp_name_list: null,
      index: null,
      conf_num: 0,
      sc_status: 0,
      modal1_flg: false,
      modal2_flg: false,
      modal3_flg: false,
    };
  },
  mounted() {
    this.$store.state.common.sIsLoading = false;
    document.title = "案件詳細";
  },
  computed: {
    ret_status: function () {
      switch (this.sc_status) {
        case 0:
          return "依頼未確定";
        case 1:
          return "実施期間待ち";
        case 2:
          return "要：アンケート用紙印刷";
        case 3:
          return "ストレスチェック実施中";
        case 4:
          return "ストレスチェック実施期間終了";
        case 6:
          return "結果分析待ち";
        case 7:
          return "結果アップロード待ち";
        case 8:
          return "ストレスチェック完了";
        case 9:
          return "分析中";
        case 10:
          return "依頼取り下げ";
      }
      return "不明";
    },
    returnStatus: function () {
      return function (num) {
        let str = "";
        console.log(this.pdf_status[num]);
        if (this.pdf_status[num] != null) {
          if (this.pdf_status[num] == -1) {
            str = "コメント自動挿入エラー";
          } else if (this.pdf_status[num] == 0) {
            str = "";
          } else if (this.pdf_status[num] == 1) {
            str = "コメント自動挿入";
          } else if (this.pdf_status[num] == 2) {
            str = "コメント手動入力";
          }
        }
        return str;
      };
    },
  },
  created() {
    this.$store.state.common.sIsLoading = true;
    this.$store.state.common.sDataLoading = true;
    this.status = this.$store.state.login_status_i;
    this.checkLogined();
    if (this.status) {
      let id = this.$route.path;
      this.sc_id = id.replace("/intelligent-detail/", "");
      this.get_implementation_data_detail();
      this.$store.state.common.sDataLoading = false;
      console.log(this.sc_id);
    }
  },
  methods: {
    get_input_data() {
      this.$axios({
        url: "/intelligent/get_input_data",
        method: "post",
        responseType: "blob",
        data: {
          sc_id: this.sc_id,
        },
      })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/zip" });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "input_data.zip";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    downloadCsvBlob(blob, fileName) {
      if (window.navigator.msSaveOrOpenBlob) {
        // for IE,Edge
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        // for chrome, firefox
        const url = URL.createObjectURL(
          new Blob([blob], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
          })
        );
        const linkEl = document.createElement("a");
        linkEl.href = url;
        linkEl.setAttribute("download", fileName);
        document.body.appendChild(linkEl);
        linkEl.click();

        URL.revokeObjectURL(url);
        linkEl.parentNode.removeChild(linkEl);
      }
    },
    get_high_stress_list() {
      this.$axios({
        method: "POST",
        url: "/intelligent/get_highstress_employee_list",
        data: {
          email: this.$store.state.email,
          pw: this.$store.state.pass,
          sc_id: this.sc_id,
        },
        responseType: "blob",
        dataType: "binary",
      })
        .then((response) => {
          let xlsx_file = response.data;
          let blob = new Blob([xlsx_file], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let filename =
            this.sc_id +
            "_" +
            this.detail.sc_com_name +
            "_高ストレス者リスト.xlsx";
          const linkEl = document.createElement("a");
          linkEl.href = window.URL.createObjectURL(blob);
          linkEl.setAttribute("download", filename);
          document.body.appendChild(linkEl);
          linkEl.click();

          URL.revokeObjectURL(window.URL.createObjectURL(blob));
          linkEl.parentNode.removeChild(linkEl);
          // let link = document.createElement("a");
          // link.href = window.URL.createObjectURL(blob);
          // link.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    get_answer_status_list() {
      this.$axios({
        method: "POST",
        url: "/intelligent/get_implementation_data_detail_list",
        data: {
          email: this.$store.state.email,
          pw: this.$store.state.pass,
          sc_id: this.sc_id,
        },
        responseType: "blob",
        dataType: "binary",
      })
        .then((response) => {
          let xlsx_file = response.data;
          let blob = new Blob([xlsx_file], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let filename =
            this.sc_id +
            "_" +
            this.detail.sc_com_name +
            "_ストレスチェック対象者リスト.xlsx";
          const linkEl = document.createElement("a");
          linkEl.href = window.URL.createObjectURL(blob);
          linkEl.setAttribute("download", filename);
          document.body.appendChild(linkEl);
          linkEl.click();

          URL.revokeObjectURL(window.URL.createObjectURL(blob));
          linkEl.parentNode.removeChild(linkEl);
          // let link = document.createElement("a");
          // link.href = window.URL.createObjectURL(blob);
          // link.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancel_request() {
      let result = window.confirm("案件を取り下げますか？");
      if (result == true) {
        let input = window.prompt("確認のため「確認」と入力してください。");
        if (input == "確認") {
          this.$axios
            .post("/intelligent/cancel_request", {
              sc_id: this.sc_id,
            })
            .then((response) => {
              if (response.data.flg) {
                window.alert("案件を取り下げました。");
                this.get_implementation_data_detail();
              } else {
                window.alert("案件取り下げに失敗しました");
              }
            })
            .catch((error) => {
              console.log(error);
              window.alert("案件取り下げをキャンセルしました。");
            });
        } else {
          window.alert("案件取り下げをキャンセルしました。");
        }
      }
      this.get_implementation_data_detail();
    },

    result_upload(e) {
      if (!window.confirm("分析結果をアップロードします")) return;
      this.$store.commit("common/doStartServerProcess");
      this.$store.state.common.sIsLoading = true;
      this.$store.state.common.sDataLoading = true;
      const files = e.target.files || e.dataTransfer.files;
      console.log(files);
      var params = new FormData();
      params.append("file", files[0]);
      params.append("sc_id", this.sc_id);
      console.log(params);
      this.$axios
        .post("/intelligent/upload_result_file", params)
        .then((response) => {
          this.$store.commit("common/doEndServerProcess");
          this.$store.state.common.sIsLoading = false;
          this.$store.state.common.sDataLoading = false;
          window.alert("アップロードが完了しました");
          console.log(response);
        })
        .catch((error) => {
          this.$store.commit("common/doEndServerProcess");
          this.$store.state.common.sIsLoading = false;
          this.$store.state.common.sDataLoading = false;
          window.alert("アップロード時にエラーが発生しました");
          console.log(error);
        });
      return;
      // let flg = false;
      // for (let data of this.targets_list) {
      //   if (data.answer_status == 2) {
      //     flg = true;
      //   }
      // }
      // if (flg == true) {
      //   this.$axios
      //     .post("/intelligent/auto_operate", {
      //       sc_id: this.sc_id,
      //     })
      //     .then((response) => {
      //       console.log(response);
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //     });
      // } else {
      //   window.alert("回答済みの従業員がいません");
      // }
    },
    output_report() {
      this.$axios
        .post("/intelligent/output_report", {
          sc_id: this.sc_id,
        })
        .then((response) => {
          if (response.data.flg) {
            window.open(
              window.URL.createObjectURL(this.make_blob(response.data.pdf))
            );
          }
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // update_status() {
    //   if (!window.confirm("状態を更新しますか？")) return;
    //   this.$axios
    //     .post("/intelligent/update_status", {
    //       sc_id: this.sc_id,
    //       sc_status: this.sc_status,
    //     })
    //     .then((response) => {
    //       if (response.data.flg) {
    //         alert("状態を更新しました");
    //         this.get_implementation_data_detail();
    //       } else {
    //         alert("状態の更新に失敗しました");
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    db_to_csv() {
      this.$axios({
        method: "POST",
        url: "/intelligent/download_excel",
        data: {
          sc_id: this.sc_id,
        },
        responseType: "blob",
        dataType: "binary",
      })
        .then(
          function (response) {
            console.log(response);
            this.downloadCsvBlob(
              response.data,
              this.sc_id +
                "_" +
                this.detail.sc_com_name +
                "_従業員宛名リスト.xlsx"
            );
          }.bind(this)
        )
        .catch(
          function (error) {
            console.log(error);
          }.bind(this)
        );
    },
    csv_to_db(e) {
      let csv_file = e.target.files[0];
      const params = new FormData();
      params.append("file", csv_file);
      params.append("sc_id", this.sc_id);
      this.$axios
        .post("/intelligent/input_excel_file", params, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((result) => {
          if (result.data.flg) {
            alert("csvファイルのアップロードに成功しました");
            this.get_implementation_data_detail();
          } else {
            alert("csvファイルのアップロードに失敗しました");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createBlob(uri) {
      let mtype = "data:application/pdf;base64,";
      // let byteString = atob( uri.split( "," )[1] ) ;
      // let mimeType = uri.match( /(:)([a-z\\/]+)(;)/ )[2] ;
      let byteString = uri;
      let mimeType = mtype;
      let content;
      for (
        let i = 0, l = byteString.length, content = new Uint8Array(l);
        l > i;
        i++
      ) {
        content[i] = byteString.charCodeAt(i);
      }

      let blob = new Blob([content], {
        type: mimeType,
      });

      let a = window.URL.createObjectURL(blob);
      console.log(a);
      return a;
    },
    get_implementation_data_detail() {
      console.log("sc_id = " + this.sc_id);
      const sc_id1 = {
        sc_id: this.sc_id,
      };
      this.$axios
        .post("/intelligent/get_implementation_data_detail", sc_id1)
        .then((response) => {
          console.log(response);
          if (response.data.flg) {
            this.detail = response.data.implementation_data.detail;
            this.sc_status = this.detail.sc_status;
            this.targets_list = response.data.implementation_data.targets_info;
            for (let li of this.targets_list) {
              if (li.sc_conf_answer == 1) {
                this.ank_num += 1;
              }

              if (li.sc_conf_notice == 1) {
                this.conf_num += 1;
              }
            }
            console.log(this.conf_num);
            console.log(this.detail);
          } else {
            console.log("エラー");
          }
          this.$store.state.common.sDataLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.$store.state.common.sDataLoading = false;
        });
    },
    checkLogined() {
      if (!this.status) {
        this.$router.push("/intelligent-login");
      }
    },
    make_blob(base64_str) {
      let dataURI = "data:application/pdf;base64," + base64_str;
      // "iVBORw..."をバイナリに変換
      var byteString = atob(dataURI.split(",")[1]);

      // "image/png"
      var mimeType = dataURI.match(/(:)([a-z/]+)(;)/)[2];

      // バイナリからBlobを作成
      for (
        var i = 0, l = byteString.length, content = new Uint8Array(l);
        l > i;
        i++
      ) {
        content[i] = byteString.charCodeAt(i);
      }

      var blob = new Blob([content], {
        type: mimeType,
      });
      return blob;
      // window.open(window.URL.createObjectURL( blob )) ;
    },
  },
};
</script>

<style scoped>
div {
  overflow-y: auto;
  max-height: 100%;
  height: 100%;
  margin-top: 30px;
  margin-bottom: 10px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

table tr {
  border-bottom: solid 1px #eee;
  cursor: pointer;
}

table tbody tr:hover {
  background-color: #d4f0fd;
}

table th,
table td {
  text-align: center;
  width: 10%;
  padding: 15px 0;
}

input[type="checkbox"] {
  transform: scale(1.4);
}

.fixed01 {
  position: sticky;
  top: 0;
  color: #fff;
  background: #333;

  &:before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
    border: 1px solid #eee;
  }
}

.fixed02 {
  position: sticky;
  top: 0;
  color: #222222;
  background: #ffffff;

  &:before {
    content: "";
    position: ;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
    border: 1px solid #eee;
  }
}

table {
  text-align: center;
  width: 100%;
}

table tbody tr:hover {
  background: #d4f0fd;
  cursor: pointer;
}

.overlays {
  /* 要素を重ねた時の順番 */
  z-index: 1;

  /* 画面全体を覆う設定 */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  /* 画面の中央に要素を表示させる設定 */
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlays .cancel {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  cursor: default;
}

.overlays:target {
  visibility: visible;
  opacity: 1;
}

.modals {
  margin: 0px auto;
  padding: 20px;
  background: #fff;
  border: 1px solid #666;
  width: 80%;
  height: 90%;
  border-radius: 6px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
  position: relative;
}

.modals h2 {
  margin-top: 0;
}

.modals .close {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 20px;
  right: 20px;
  opacity: 0.8;
  transition: all 200ms;
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
  color: #777;
}

.modals .close:hover {
  opacity: 1;
}

.modals .cont {
  overflow-y: auto;
  max-height: 80vh;
}

.modals p {
  margin: 0 0 1em;
  text-align: center;
}

.modals p:last-child {
  margin: 0;
}

a.button:hover {
  box-shadow: 0 12px 23px rgba(0, 0, 0, 0.23), 0 10px 10px rgba(0, 0, 0, 0.19);
}

.overlays table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.overlays table tr {
  border-bottom: solid 1px #eee;
  cursor: pointer;
}

.overlays table tbody tr:hover {
  background-color: #d4f0fd;
}

.overlays table th,
.overlays table td {
  text-align: center;
  width: 10%;
  padding: 15px 0;
}

div {
  display: inline-block;
  width: 100%;
}

#data-area {
  max-height: 80vh;
  overflow-y: auto;
}

.tag-group {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  zoom: 1;
  /* Fix for IE7 */
  *display: inline;
  /* Fix for IE7 */
}

ul {
  list-style: none;
  text-align: center;
}

.tab-group li {
  border: 1px solid #428bca;
  border-radius: 4px;
  position: relative;
  float: left;
  padding: 10px 15px 10px 15px;
}

.tab-group li:hover {
  background-color: #d4f0fd;
  cursor: pointer;
}

.active {
  background-color: #428bca;
  color: #fff;
}

.tab-group li + li {
  margin-left: -1px;
}

.tab-group > li:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.tab-group > li:first-child {
  margin-left: 0;
}

.tab-group > li:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.tab-group > li:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

table {
  text-align: center;
  width: 100%;
}

table tbody tr:hover {
  background: #d4f0fd;
  cursor: pointer;
}

[v-cloak] {
  display: none;
  cursor: wait;
}
</style>
